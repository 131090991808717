<template>
  <div>
    <Form inline>

    </Form>
    <Table
      stripe
      :data="comments"
      :columns="tableColumns"
      style="width: 100%"
      :loading="tableLoading"
      ref="selection"
    />
    <Pager :total="commentListCount" :current.sync="page" />
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue"
import { queryOrderComment } from "@/api"
import "@/components/tableUtil"

export default {
  name: "CommentsSelector",
  components: { Pager },
  props: {
    orderNo: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      comments: [],
      commentListCount: 0,
      page: 1,
      tableLoading: false,
      tableColumns: [
        { title: "ID", key: "id", width: 50, maxWidth: 50 },
        { title: "订单号", key: "orderNo", width: 200, maxWidth: 200 },
        { title: "用户", key: "user", width: 100, maxWidth: 100 },
        { title: "备注", key: "comment", width: 300, maxWidth: 300 },
        {
          title: "创建时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
      ],
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },

  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const { items, count } = await queryOrderComment(this.orderNo, this.page)
        this.comments = items
        this.commentListCount = count
        console.log(items)
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
  },
}
</script>