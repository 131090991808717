<template>
  <span>
    {{ toHumanDate(time, defText) }}
  </span>
</template>

<script>
import { toHumanDate } from "@/util"

export default {
  name: "DateHolder",
  components: {},
  props: {
    time: { type: Number, default: 0 },
    defText: { type: String, default: "-" },
  },
  data() {
    return { toHumanDate }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>
