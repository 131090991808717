<template>
  <ContentWrapper :links="topLinks">
    <Form v-if="order.orderNo" :labelWidth="150">
      <FormItem label="订单号">{{ order.orderNo }}</FormItem>
      <FormItem label="卖家昵称">{{ order.seller.nickname }}</FormItem>
      <FormItem label="发货地址" v-if="order.sellerAddress.fullAddress">{{ order.sellerAddress.fullAddress }}</FormItem>
      <FormItem label="发货联系人" v-if="order.sellerAddress.fullAddress">{{ order.sellerAddress.contact }}</FormItem>
      <FormItem label="发货电话" v-if="order.sellerAddress.fullAddress">{{ order.sellerAddress.phone }}</FormItem>
      <FormItem label="买家昵称">{{ order.buyer.nickname }}</FormItem>
      <FormItem label="收件地址" v-if="order.buyerAddress.fullAddress">{{ order.buyerAddress.fullAddress }}</FormItem>
      <FormItem label="收件联系人" v-if="order.buyerAddress.fullAddress">{{ order.buyerAddress.contact }}</FormItem>
      <FormItem label="收件电话" v-if="order.buyerAddress.fullAddress">{{ order.buyerAddress.phone }}</FormItem>
      <FormItem label="金额">{{ centiToOne(order.price) }}</FormItem>
      <FormItem label="运费">{{ centiToOne(order.shippingFee) }}</FormItem>
      <FormItem label="优惠金额">{{ centiToOne(order.promotedPrice) }}</FormItem>
      <FormItem label="商品总额">{{ centiToOne(order.itemTotalPrice) }}</FormItem>
      <FormItem label="商品">
        <Table size="small" :data="order.itemList" :columns="itemColumns"></Table>
      </FormItem>
      <FormItem label="支付渠道"> {{ order.paymentChannel ? order.paymentChannel : "-" }}</FormItem>
      <FormItem label="买家实付款"> {{ centiToOne(order.realPayPrice) }}</FormItem>
      <FormItem label="状态"> {{ humanOrderStatus(order.status) }}</FormItem>
      <FormItem label="自取"> {{ order.shippingMethod == "SELF_PICKUP" ? "是" : "否" }}</FormItem>
      <FormItem label="物流方式" v-if="order.shippingMethod == 'DELIVERY_BY_EXPRESS'"> {{ humanShippingFeeType(order.shippingFeeType) }}</FormItem>
      <FormItem label="备注">
        {{ order.comment ? order.comment : "-" }}
        <Button v-if="order.comment" @click="showCommentsHistory  =  !showCommentsHistory">查看历史备注</Button>
      </FormItem>
      <FormItem label="创建时间">{{ toHumanDate(order.createdAt, "-", false) }}</FormItem>
      <FormItem label="最后变动时间">{{ toHumanDate(order.updatedAt, "-", false) }}</FormItem>
      <FormItem label="支付时间">{{ toHumanDate(order.payedAt, "-", false) }}</FormItem>
      <FormItem label="确认收货时间">{{ toHumanDate(order.confirmedAt, "-", false) }}</FormItem>
      <FormItem label="关闭时间">{{ toHumanDate(order.closedAt, "-", false) }}</FormItem>
      <FormItem label="关联的支付单号">
        <Table size="small" :data="order.receipts" :columns="receiptColumns"></Table>
      </FormItem>
    </Form>
    <Modal v-model="showCommentsHistory" :closable="true" title="查看备注历史" width="45%">
      <CommentSelector :orderNo="orderNo" v-if="showCommentsHistory" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { getOrderDetail } from "@/api"
import { centiToOne, humanOrderStatus, humanShippingFeeType, toHumanDate, imgHolder, badgeReceiptStatus, humanReceiptStatus } from "@/util"
import "@/components/tableUtil"
import CommentSelector from "../../components/order/CommentSelector"

export default {
  name: "OrderQueryPage",
  components: { ContentWrapper, CommentSelector },
  data() {
    return {
      topLinks: [
        { title: "订单管理", link: "/tx/orders" },
        { title: "订单详情", link: this.$route.fullPath },
      ],
      loading: false,
      order: {},
      centiToOne,
      toHumanDate,
      humanOrderStatus,
      humanShippingFeeType,
      showCommentsHistory: false,
      itemColumns: [
        { title: "标识", key: "itemAlias", maxWidth: 100 },
        { title: "主图", render: (h, p) => imgHolder(h, p.row.image?.url), maxWidth: 90 },
        { title: "标题", key: "title" },
        { title: "价格", render: (h, p) => h("span", centiToOne(p.row.price)), maxWidth: 100 },
        { title: "数量", key: "quantity", maxWidth: 100 },
      ],
      receiptColumns: [
        { title: "流水号", key: "receiptNo", width: 160, maxWidth: 160 },
        { title: "外部流水号", key: "externalReceiptNo", width: 200, maxWidth: 200 },
        {
          title: "状态",
          key: "statusText",
          width: 70,
          maxWidth: 70,
        },
        {
          title: "支付渠道",
          key: "paymentChannel",
          width: 80,
          maxWidth: 80,
        },
        {
          title: "创建时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "支付时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.paidAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "接口回调时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.callbackAt, defText: "-" },
            }),
          width: 110,
          maxWidth: 110,
        },
      ]
    }
  },
  computed: {
    orderNo() {
      const { query } = this.$route
      if (!query) {
        throw new Error("错误的订单号")
      }
      const { orderNo } = query
      if (!orderNo) {
        throw new Error("订单号不存在")
      }
      return orderNo
    },
  },
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.loading = true
        const order = await getOrderDetail(this.orderNo)
        this.order = order
        this.loading = false
      } catch (e) {
        this.$Message.error(e.message)
        this.loading = false
      }
    },
  },
}
</script>
