<template>
  <i-switch :true-value="on" :false-value="off" v-model="v" />
</template>

<script>
export default {
  name: "StatusHelper",
  components: {},
  props: {
    value: { type: Number, default: 0 },
    off: { type: Number, default: 0 },
    on: { type: Number, default: 1 },
  },
  data() {
    return {
      v: this.value,
    }
  },
  computed: {},
  watch: {
    value(newV) {
      this.v = newV
    },
    v(newV) {
      this.$emit("change", newV)
    },
  },
  mounted() {},
  methods: {},
}
</script>
